export var E_Theme;
(function (E_Theme) {
    E_Theme["Normal"] = "normal";
    E_Theme["Blue"] = "blue";
})(E_Theme || (E_Theme = {}));
function toThemeClass(theme) {
    return `theme-${theme}`;
}
export let currentTheme = E_Theme.Normal;
export function setTheme(theme) {
    if (theme === currentTheme) {
        return;
    }
    const currentKlass = toThemeClass(currentTheme);
    const nextKlass = toThemeClass(theme);
    document.body.classList.remove(currentKlass);
    document.body.classList.add(nextKlass);
    currentTheme = theme;
}
