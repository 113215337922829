const routes = [
    {
        path: '/',
        name: 'mainLogin',
        component: () => import(/* webpackChunkName: "mainLogin" */ '@/presentation/pages/MainLogin/MainFrame.vue'),
    },
    {
        path: '/eureka-login',
        name: 'eurekaLogin',
        component: () => import(/* webpackChunkName: "eurekaLogin" */ '@/presentation/pages/EurekaLogin/MainFrame.vue'),
    },
    {
        path: '/sub-login',
        name: 'subLogin',
        component: () => import(/* webpackChunkName: "subLogin" */ '@/presentation/pages/SubLogin/SubLogin.vue'),
    },
    {
        path: '/*',
        redirect: '/',
    }
];
export default routes;
