/**
 * warning: 使用js而不是js 使用Babel优化插件
 */
import Vue from 'vue';
import _ from 'lodash';
import { Button, Input, Tabs, TabPane, Form, FormItem, Checkbox, MessageBox, Autocomplete, Select, Option, Col, Dialog, Loading } from 'element-ui';
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 2000 };
_.each([
    Button, Autocomplete, Input, Tabs, TabPane, Form, FormItem, Checkbox, Select, Option, Col, Dialog, Loading.directive
], component => {
    Vue.use(component);
});
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
