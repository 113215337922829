/**
 * 登录方式
 */
export var E_LoginMethod;
(function (E_LoginMethod) {
    E_LoginMethod["password"] = "EMAIL_PASSWORD";
    E_LoginMethod["mobilePassword"] = "MOBILE_PASSWORD";
    E_LoginMethod["sms"] = "MOBILE_VERIFY_CODE";
    E_LoginMethod["carsi"] = "carsi";
    E_LoginMethod["wechat"] = "WECHAT_QR_CODE";
    E_LoginMethod["emailVerifyCode"] = "EMAIL_VERIFY_CODE";
    E_LoginMethod["google"] = "GOOGLE";
})(E_LoginMethod || (E_LoginMethod = {}));
export var E_SSORestrictType;
(function (E_SSORestrictType) {
    // DINGTLK、WECHAT_ENTERPRISE、FEISHU、JWT、UNKNOWN
    E_SSORestrictType["DINGTLK"] = "DINGTLK";
    E_SSORestrictType["WECHAT_ENTERPRISE"] = "WECHAT_ENTERPRISE";
    E_SSORestrictType["FEISHU"] = "FEISHU";
    E_SSORestrictType["JWT"] = "JWT";
    E_SSORestrictType["UNKNOWN"] = "UNKNOWN";
})(E_SSORestrictType || (E_SSORestrictType = {}));
/**
 * bo配置的登录方式
 */
export var E_LoginChannel;
(function (E_LoginChannel) {
    E_LoginChannel["wechat"] = "wechat";
    E_LoginChannel["account"] = "account";
    E_LoginChannel["sms"] = "sms";
    E_LoginChannel["sso"] = "sso";
})(E_LoginChannel || (E_LoginChannel = {}));
