import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import { getVar } from 'library-components/utils/environment';
import { DEPLOY_ENV } from 'library-components/config/envConfig';
import _ from 'lodash';
const regionList = ['cn', 'us', 'eu'];
const isCN = (_.includes(regionList, DEPLOY_ENV) ? DEPLOY_ENV : 'cn') === 'cn';
Vue.use(Router);
const router = new Router({
    base: '/',
    mode: 'hash',
    routes: routes,
});
// 兼容Eureka登录跳转地址
router.beforeEach((to, from, next) => {
    const productName = getVar('from');
    if (productName === 'eureka' && from.name !== 'eurekaLogin' && to.name !== 'eurekaLogin' && isCN) {
        next({ name: 'eurekaLogin' });
    }
    next();
});
export default router;
