import { get } from 'lodash';
import { createUniformSetting } from '@patsnap/uniform-setting';
import { baseUserServer, baseAuthServer } from 'library-components/config/apiConfig.js';
import { psAxios } from '@patsnap/common-ps-http';

let setting = null;
function initLocale(isLogin = true) {
    const region = get(window, 'zPREFACE.region', 'us').toLocaleLowerCase();
    if (setting) {
        setting.locale.LoginIn = isLogin;
    } else {
        setting = createUniformSetting({
            region,
            loggedIn: isLogin,
            baseAuthUrl: baseAuthServer,
            baseAccountUrl: baseUserServer,
            axiosInstance: psAxios,
        });
    }
    return setting.locale;
}

export async function getLangValue(isLogin) {
    const { default: default_lang, user } = await initLocale(isLogin).getLocale();
    return user || default_lang;
}

export async function setLangValue(lang, isLogin) {
    await initLocale(isLogin).setLocale(lang);
}
