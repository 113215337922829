import { psAxios } from '@patsnap/common-ps-http';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import createError from 'axios/lib/core/createError';

/**
 * 对应不上的请求错误码通过 error message 逆推
 */
const httpStatusCodes = {
    'Network Error': 440,
};

const getErrorMessage = (error_code, error_params = {}, i18n, prefix = 'commonStatusCode') => {
    if (!i18n || !i18n.te) return '';
    const i18n_key = `${prefix}.${error_code}`;
    if (i18n.te(i18n_key)) return i18n.t(i18n_key, error_params);
    return i18n.t('commonStatusCode.400');
};

const processResponse = (response, i18n, prefix = 'commonStatusCode') => {
    const { transformCode } = response.config;
    const _error_code = get(response, 'data.error_code', 400);
    if (transformCode && _error_code !== 0) {
        const _error_params = get(response, 'data.error_params') || {};
        const _error_msg = getErrorMessage(_error_code, _error_params, i18n, prefix);
        response.data = isObject(response.data)
            ? {
                ...response.data,
                _error_code,
                _error_msg,
            }
            : {
                _error_code,
                _error_msg,
            };
    }
    return transformCode;
};

const processErrorResponse = (error, i18n, prefix = 'commonStatusCode') => {
    // 如果错误没有response则构造一个
    const { code, message, config } = error.toJSON();
    const { transformCode } = config;
    if (!transformCode) return transformCode;
    if (!error.response) {
        error.response = {
            config,
            data: {
                error_code: code || httpStatusCodes[message],
            },
        };
    } else {
        // 补上状态码错误
        error.response.data.error_code = code || httpStatusCodes[message] || `sc.${error.response.status}`;
    }
    return processResponse(error.response, i18n, prefix);
};

export const initTransformCodeInterceptor = (i18n, prefix = 'commonStatusCode') => {
    // 在then中的 状态200的业务错误
    // error_code 必然存在
    const transformCodeThenInterceptor = response => {
        if (!processResponse(response, i18n, prefix)) {
            return response;
        }
        const { _error_code, _error_msg } = response.data;

        if (get(response, 'data.status')) {
            return response;
        }

        const newAxiosError = createError(
            `Request failed with status code ${_error_code}`,
            response.config,
            null,
            response.request,
            response
        );

        newAxiosError._error_code = _error_code;
        newAxiosError._error_msg = _error_msg;
        return Promise.reject(newAxiosError);
    };

    // 在catch中的 状态200的业务错误和请求状态码非200的错误
    // error_code 未必存在，存在的时候应该不为0
    const transformCodeCatchInterceptor = error => {
        if (!processErrorResponse(error, i18n, prefix)) {
            return Promise.reject(error);
        }
        const { _error_code, _error_msg } = error.response.data;

        error._error_code = _error_code;
        error._error_msg = _error_msg;
        return Promise.reject(error);
    };

    psAxios.interceptors.response.use(transformCodeThenInterceptor, transformCodeCatchInterceptor);
    // psAxios.interceptors.request.use(
    //     function(config) {
    //         config.headers['X-API-Version'] = '2.0';
    //         return config;
    //     }
    // );
};
