import _ from 'lodash';
import qs from 'qs';
import { E_PRODUCTS_NAME, E_WECHAT_APP_NAME, INCITATION_CODE_TYPE } from 'library-components/apis/type';
import { getVar } from 'library-components/utils/environment';
import { PASSPORT_CLIENT_ID, PASSPORT_CLIENT_NAME, YUNCHUANG_URI, DEPLOY_ENV, ACCOUNT_URI, PASSPORT_URI, } from 'library-components/config/envConfig.js';
import { E_Language, E_PROJECT_NAME } from 'library-components/config/types';
import { getLoginTabTag } from './localStorage';
import { E_LoginChannel, E_LoginMethod } from 'library-components/apis/login/login.type';
const regionList = ['cn', 'us', 'eu'];
const languages = _.values(E_Language);
export class CommonService {
    constructor() {
        this.initData = {
            globalData: {},
        };
    }
    init(initData) {
        this.initData = initData;
    }
    get globalData() {
        return _.get(this, 'initData.globalData', {});
    }
    get from() {
        return getVar('from') || PASSPORT_CLIENT_NAME;
    }
    get client_id() {
        return getVar('client_id') || PASSPORT_CLIENT_ID;
    }
    get Lang() {
        return _.get(this, 'initData.lang') || E_Language.cn;
    }
    get Region() {
        if (getVar('region')) {
            return getVar('region');
        }
        return _.get(this, 'initData.region') || _.upperCase(_.includes(regionList, DEPLOY_ENV) ? DEPLOY_ENV : 'cn');
    }
    get AccountInfo() {
        return _.get(this, 'initData.accountInfo', {});
    }
    get ProjectName() {
        return _.get(this, 'initData.projectName');
    }
    get IsLoginProject() {
        return this.ProjectName === E_PROJECT_NAME.Login;
    }
    get IsRegionEU() {
        return _.upperCase(this.Region) === 'EU';
    }
    get IsRegionUS() {
        return _.upperCase(this.Region) === 'US';
    }
    get IsRegionCN() {
        return _.upperCase(this.Region) === 'CN';
    }
    get DefaultCountryCode() {
        return this.IsRegionCN ? '+86' : '';
    }
    get CompanyId() {
        return _.get(this, 'initData.globalData.company_id', '');
    }
    get CompanyName() {
        if (getVar('company_name')) {
            return getVar('company_name');
        }
        return _.get(this, 'initData.globalData.company_name', '');
    }
    get DomainRestriction() {
        return _.get(this, 'initData.globalData.domain_restriction', '');
    }
    get AccountHost() {
        return ACCOUNT_URI;
    }
    get PassportHost() {
        return PASSPORT_URI;
    }
    get YunChuangHost() {
        return YUNCHUANG_URI;
    }
    get AllowRegiser() {
        if (getVar('allowRegister')) {
            return getVar('allowRegister') === 'true';
        }
        return _.get(this, 'initData.globalData.allow_register');
    }
    get AnonymousLogin() {
        if (getVar('anonymousLogin')) {
            return getVar('anonymousLogin') === 'true';
        }
        return _.get(this, 'initData.globalData.allow_anonymous_login', false);
    }
    get SupportEmail() {
        if (this.IsRegionCN) {
            return 'support@patsnap.com';
        }
        else {
            return 'help@patsnap.com';
        }
    }
    get SupportPhoneNumber() {
        return '400-694-4481';
    }
    get TermsOfService() {
        if (this.IsRegionCN) {
            return 'https://www.zhihuiya.com/legal/terms';
        }
        return 'https://www.patsnap.com/legal/general-patsnap-services-terms-and-conditions-current/';
    }
    get PrivacyPolicy() {
        if (this.IsRegionCN) {
            return 'https://www.zhihuiya.com/legal/privacy';
        }
        return 'https://www.patsnap.com/legal/privacy-policy';
    }
    get HelpUrl() {
        if (this.IsRegionCN) {
            return 'https://help.zhihuiya.com/help';
        }
        return 'https://help.patsnap.com/hc/en-us/requests/new';
    }
    get UseCountryCode() {
        return this.IsRegionCN;
    }
    get AlertUserRegisterCheckEmail() {
        return !this.IsRegionCN;
    }
    get UseNickName() {
        return this.IsRegionCN;
    }
    get MobileRequired() {
        return this.IsRegionCN;
    }
    get UseMobileVerify() {
        return this.IsRegionCN;
    }
    get EmailRequired() {
        return !this.IsRegionCN;
    }
    get TrialFormShowSubTitle() {
        return this.IsRegionCN;
    }
    get IsShowGoogleLogin() {
        const { from } = this.getCommonParams();
        return _.includes([
            E_PRODUCTS_NAME.discovery,
            E_PRODUCTS_NAME.synapse,
            E_PRODUCTS_NAME.eureka,
            E_PRODUCTS_NAME.ls360
        ], from) && !this.IsRegionCN;
    }
    /**
     * URL上拿的通用参数
     */
    getCommonParams() {
        return {
            from: (getVar('from') && _.toLower(getVar('from'))) || PASSPORT_CLIENT_NAME,
            redirect_uri: getVar('redirect_uri'),
            client_id: getVar('client_id') || PASSPORT_CLIENT_ID,
        };
    }
    get IsFromSynapse() {
        const { from } = this.getCommonParams();
        return from === E_PRODUCTS_NAME.synapse;
    }
    get IsFromFreemium() {
        const { from } = this.getCommonParams();
        return E_PRODUCTS_NAME.isFreeProduct(from) && !this.AnonymousLogin && !this.AllowRegiser;
    }
    get IsCanFreeApplyProduct() {
        const { from } = this.getCommonParams();
        return !E_PRODUCTS_NAME.notFreeApplyProducts(from);
    }
    get IsFromEureka() {
        const { from } = this.getCommonParams();
        return from === E_PRODUCTS_NAME.eureka;
    }
    get CanEurekaFreeRegister() {
        return this.IsFromEureka && !this.AnonymousLogin && !this.AllowRegiser;
    }
    get IsFromFreeProduct() {
        const { from } = this.getCommonParams();
        return E_PRODUCTS_NAME.isFreeProduct(from) || this.IsFromEureka;
    }
    get IsFromPaidProduct() {
        const { from } = this.getCommonParams();
        return E_PRODUCTS_NAME.isPaidProduct(from);
    }
    get LoginTooltip() {
        const tooltip = _.get(this, 'initData.globalData.login_tooltip', {});
        // 每种语言是否都有值
        const allHasValue = _.every(languages, (lang) => {
            return !_.isEmpty(tooltip[lang]);
        });
        return allHasValue && _.get(tooltip, this.Lang, null);
    }
    get LoginTitle() {
        const title = _.get(this, 'initData.globalData.login_title', {});
        // 每种语言是否都有值
        const allHasValue = _.every(languages, (lang) => {
            return !_.isEmpty(title[lang]);
        });
        return allHasValue && _.get(title, this.Lang, null);
    }
    /**
     * 默认登录方式
     * 来自免费产品，默认微信
     * 来自非免费产品，默认邮箱
     * 没有来源产品，默认微信
     */
    get DefaultLoginMethod() {
        const tab = getLoginTabTag() || getVar('tab');
        if (!this.IsRegionCN) {
            return E_LoginMethod.password;
        }
        if (_.indexOf(this.LoginMethod, tab) >= 0) {
            return tab;
        }
        if (!this.IsFromPaidProduct) {
            if (!this.CanWechatLogin && _.includes(this.LoginMethod, E_LoginMethod.password)) {
                return E_LoginMethod.password;
            }
            if (_.includes(this.LoginMethod, E_LoginMethod.wechat)) {
                return E_LoginMethod.wechat;
            }
        }
        if (_.includes(this.LoginMethod, E_LoginMethod.password)) {
            return E_LoginMethod.password;
        }
        // 如果没有取LoginMethod的第一个
        return this.LoginMethod.length > 0 ? this.LoginMethod[0] : null;
    }
    /**
     * bo配置的login_channel
     */
    get LoginChannel() {
        if (getVar('login_channel')) {
            return _.split(getVar('login_channel'), ',');
        }
        const defaultLoginChannel = `${E_LoginChannel.wechat},${E_LoginChannel.account},${E_LoginChannel.sms},${E_LoginChannel.sso}`;
        return _.split(_.get(this, 'initData.globalData.login_channel', defaultLoginChannel), ',');
    }
    get LoginMethod() {
        let loginMethodList = this.IsRegionCN ? [E_LoginMethod.wechat, E_LoginMethod.password, E_LoginMethod.sms] : [E_LoginMethod.password];
        if (this.IsRegionCN) {
            if (!this.CanWechatLogin) {
                loginMethodList = _.without(loginMethodList, E_LoginMethod.wechat);
            }
            if (!this.CanLoginByPassword) {
                loginMethodList = _.without(loginMethodList, E_LoginMethod.password);
            }
            if (!this.CanLoginBySms) {
                loginMethodList = _.without(loginMethodList, E_LoginMethod.sms);
            }
        }
        return loginMethodList;
    }
    get CanWechatLogin() {
        // 除了eureka，当屏幕宽度小于820不可以使用微信登录
        if (window.innerWidth <= 820 && !this.IsFromEureka) {
            return false;
        }
        return this.IsRegionCN && _.includes(this.LoginChannel, E_LoginChannel.wechat);
    }
    get CanLoginByPassword() {
        return _.includes(this.LoginChannel, E_LoginChannel.account);
    }
    get CanLoginBySms() {
        return this.IsRegionCN && _.includes(this.LoginChannel, E_LoginChannel.sms);
    }
    get CanLoginBySSO() {
        return _.includes(this.LoginChannel, E_LoginChannel.sso);
    }
    /**
     * 只有SSO登录
     */
    get OnlySSOLogin() {
        return this.LoginChannel.length === 1 && this.CanLoginBySSO;
    }
    /**
     * 当前微信公众号appName
     */
    get CurrentAppName() {
        const { from } = this.getCommonParams();
        if (from === E_PRODUCTS_NAME.eureka) {
            return E_WECHAT_APP_NAME.EUREKA;
        }
        if (from === E_PRODUCTS_NAME.yunchuang) {
            return E_WECHAT_APP_NAME.YUNCHUANG;
        }
        if (from === E_PRODUCTS_NAME.synapse || from === E_PRODUCTS_NAME.ls360) {
            return E_WECHAT_APP_NAME.SYNAPSE;
        }
        // if (from === E_PRODUCTS_NAME.tffi) {
        //     return E_WECHAT_APP_NAME.TFFI
        // }
        return E_WECHAT_APP_NAME.ZHIHUIYA;
    }
    get redirectUrlQueryData() {
        const { redirect_uri } = this.getCommonParams();
        if (_.isEmpty(redirect_uri))
            return {};
        const redirectUri = decodeURIComponent(redirect_uri);
        const url = new URL(redirectUri);
        return qs.parse(url.search, {
            ignoreQueryPrefix: true,
        });
    }
    /**
     * 邀请人id
     */
    get InviterId() {
        return this.redirectUrlQueryData.inviter_id;
    }
    /**
     * 个人邀请
     */
    get IsPersonalInvitation() {
        return this.IsFromEureka && !_.isEmpty(this.InviterId) && this.IsRegionCN;
    }
    /**
     * 截取短码
     */
    truncateSortCode(code) {
        return _.truncate(code, {
            length: 12,
            omission: '',
        });
    }
    /**
     * admin 管理员邀请码
     */
    get InvitationSortCode() {
        const code = getVar('c');
        const type = getVar('c_type') || INCITATION_CODE_TYPE.SHORT_CODE;
        if (code && type === INCITATION_CODE_TYPE.SHORT_CODE) {
            return this.truncateSortCode(code);
        }
    }
    /**
     * admin 管理员邀请
     */
    get IsInvitationSortCodeLink() {
        return this.InvitationSortCode || this.InvitationCompanyId;
    }
    /**
     * 公司专属Domain注册链接
     */
    get InvitationCompanyId() {
        const code = getVar('c');
        const type = getVar('c_type');
        if (code && type === INCITATION_CODE_TYPE.COMPANY) {
            return code;
        }
    }
    get invitationSortCodeType() {
        const type = getVar('c_type') || INCITATION_CODE_TYPE.SHORT_CODE;
        return type;
    }
    goToInvitationPage() {
        const c = this.InvitationSortCode || this.InvitationCompanyId;
        window.location.href = `${this.AccountHost}/?c=${c}&c_type=${this.invitationSortCodeType}`;
    }
    goToPublicInvitationPage() {
        const c = this.InvitationSortCode || this.InvitationCompanyId;
        window.location.href = `${this.AccountHost}/public/?c=${c}&c_type=${this.invitationSortCodeType}#/register`;
    }
}
