import psHttp from '@patsnap/common-ps-http';
import { userBasicApiUrlPrefix, baseAuthServer } from '../../config/apiConfig';
import qs from 'qs';
import _ from 'lodash';
/**
 * 获取微信二维码
 */
export function getWechatQrcodeApp(appName) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/wechat/qrcode/app`,
        method: 'GET',
        params: {
            app_name: appName,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
// 轮询获取扫码状态
export function getWechatIdentifyTokenApi(params) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/wechat/identify-token-callback`,
        method: 'GET',
        params,
    }).then(res => {
        return _.get(res, 'data');
    });
}
// 绑定unionid和eureka openid
export function bindWechatOpenIdApi({ callback_value }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/wechat/bind/account/unionid/openid`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            callback_value,
        }),
    });
}
// 通过identify_token直接登录
export function wechatIdentifyTokenLoginApi({ identify_token, client_id }) {
    return psHttp({
        url: `${baseAuthServer}/login/identify-token`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
        data: qs.stringify({
            identify_token,
            client_id,
        }),
    }).then(res => {
        return _.get(res, 'data');
    });
}
// 邀请码扫码直接登录
export function wechatIdentifyTokenLoginWithInvitationCodeApi({ identify_token, client_id, invitation_code }) {
    return psHttp({
        url: `${baseAuthServer}/login/identify-token/invitation`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
        params: {
            identify_token,
            client_id,
            invitation_code,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function postPreLoginIdentifyToken({ identify_token, client_id }) {
    return psHttp({
        url: `${baseAuthServer}/public/pre_login/identify-token`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            identify_token,
            client_id,
        }),
    }).then(res => {
        return _.get(res, 'data');
    });
}
// 获取绑定状态
export function getEurekaWechatBindStatusApi() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/profile`,
        method: 'GET',
    });
}
// 解绑eureka unionid
export function unbindEurekaUnionid() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-bindings/wechat/unbind`,
        method: 'PUT',
    });
}
// 微信提醒状态
export function getWechatAlertStatusApi(types = []) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/user-settings?types=${types.join(',')}`,
        method: 'GET',
    }).then(res => _.get(res, 'data'));
}
// 修改微信提醒状态
export function updateWechatAlertStatusApi({ type, value }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/user-settings`,
        method: 'POST',
        data: {
            type,
            value,
        },
    });
}
