import { userBasicApiUrlPrefix, baseAuthServer } from '../../config/apiConfig';
import psHttp from '@patsnap/common-ps-http';
import _ from 'lodash';
import { E_LanguageLong } from 'library-components/apis/passport/types';
import qs from 'qs';
export function changeNewPasswordApi(data) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/modify/pwd`,
        data: data,
        method: 'PUT',
    });
}
/**
 * 用户无痕 /accounts/traceless
 */
export function putAccountTraceless({ user_id, incognito_flag }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/traceless`,
        data: { user_id, incognito_flag },
        method: 'PUT',
    });
}
// 授权访问
export function updateAccessApi(data) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/access`,
        data: data,
        method: 'POST',
    });
}
export function cancelAccessApi() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/access`,
        method: 'DELETE',
    });
}
// 显示设置
export function updateDisplayApi(data) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/setting`,
        data: data,
        method: 'PATCH',
    });
}
// 个人信息
export function getProfileApi() {
    const url = `${userBasicApiUrlPrefix}/account-settings/profile`;
    return psHttp({
        url,
        uuid: url,
        method: 'GET',
    }).then(res => {
        return _.get(res, 'data');
    });
}
// 更新用户信息
export function updateProfileApi(data) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/profile`,
        method: 'PUT',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
        },
    });
}
// 初始化信息
export function initSettingApi() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-settings/setting/init`,
        method: 'GET',
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function getExternalDataApi() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account-info/external-data`,
        method: 'GET',
    }).then((res) => {
        return _.get(res, 'data.data');
    });
}
export function unbindWechatApi() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/wechat/unbind`,
        method: 'POST',
    });
}
/**
 * 绑定手机号
 */
export function putAccountMobileBind({ country_code, mobile_number, code }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/mobile-number/bind`,
        method: 'PUT',
        params: {
            country_code,
            mobile_number,
            code,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 绑定手机号检查
 */
export function getAccountMobileBindPreCheck({ country_code, mobile_number, code }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/mobile-number/bind/pre-check`,
        method: 'GET',
        params: {
            country_code,
            mobile_number,
            code,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 解绑手机号
 */
export function putAccountMobileUnbind() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/mobile-number/unbind`,
        method: 'PUT',
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 绑定邮箱
 */
export function putAccountEmailBind({ email, code }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/email/bind`,
        method: 'PUT',
        params: {
            email,
            code,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 绑定邮箱检查
 */
export function getAccountEmailBindPreCheck({ email, code }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/email/bind/pre-check`,
        method: 'GET',
        params: {
            email,
            code,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 发送邮箱验证码
 */
export function postAccountEmailVerifyCode({ email, lang }) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/email/verify-code`,
        method: 'POST',
        params: {
            email,
            language: E_LanguageLong[lang || 'en'],
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 获取用户容量限制
 */
export function getTrialUserQuota() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/account/acl-info`,
        method: 'GET',
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function postEnterpriseSsoBindingUnbind(type) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/enterprise-sso-binding/unbind/${type}`,
        method: 'post',
    }).then(res => _.get(res, 'data'));
}
export function deleteInnoCloudUnbind() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/innocloud-user/user`,
        method: 'delete',
    }).then((res) => _.get(res, 'data'));
}
/**
 * 获取当前公司，当前privilege的所有会话。
 */
export function getPrivilegeSessions(params) {
    return psHttp({
        url: `${baseAuthServer}/privilege-sessions`,
        method: 'get',
        params,
        withCredentials: true,
    }).then(res => _.get(res, 'data'));
}
/**
 * 获取踢人信息 并且删除踢人信息接口（此踢人信息也被用作kong的402的诱因）
 */
export function getPrivilegeSessionKickerInfo(params) {
    return psHttp({
        url: `${baseAuthServer}/public/privilege-session-kicker-info`,
        method: 'get',
        params,
        withCredentials: true,
    }).then(res => _.get(res, 'data'));
}
export function postAccessProduct(params) {
    return psHttp({
        url: `${baseAuthServer}/access-product`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify(params),
        withCredentials: true,
    }).then(res => _.get(res, 'data'));
}
// 删除个人租户/accounts/close/individual
export function putCloseIndividual() {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/accounts/close/individual`,
        method: 'put',
    }).then(res => _.get(res, 'data'));
}
