// 登录页切换语言标志
export function setLoginLocaleStatus() {
    localStorage.setItem('LOGIN_LOCALE_CHANGE_STATUS', '1');
}
export function removeLoginLocaleStatus() {
    localStorage.removeItem('LOGIN_LOCALE_CHANGE_STATUS');
}
export function getLoginLocaleStatus() {
    return localStorage.getItem('LOGIN_LOCALE_CHANGE_STATUS');
}
// 登录页切换登录tab类型
export function setLoginTabTag(tab) {
    localStorage.setItem('LOGIN_TAB_TAG', tab);
}
export function getLoginTabTag() {
    return localStorage.getItem('LOGIN_TAB_TAG');
}
// 保存用户账号
export function setUserAccount(account) {
    localStorage.setItem('_PATSNAP_ACCOUNT', account);
}
export function getUserAccount() {
    return localStorage.getItem('_PATSNAP_ACCOUNT') || '';
}
// 保存用户手机号代码
export function setUserAccountMobileNumber(MOBILE_NUMBER, type) {
    localStorage.setItem(`_PATSNAP_ACCOUNT_MOBILE_NUMBER_${type}`, MOBILE_NUMBER);
}
export function getUserAccountMobileNumber(type) {
    return localStorage.getItem(`_PATSNAP_ACCOUNT_MOBILE_NUMBER_${type}`) || '';
}
// 保存手机验证码倒计时
export function setMobileVerifyCodeCountDownTime(verifyCodePurpose) {
    localStorage.setItem(`_PATSNAP_ACCOUNT_MOBILE_VERIFY_COUNT_DOWN_TIME_${verifyCodePurpose}`, new Date().getTime().toString());
}
export function removeMobileVerifyCodeCountDownTime(verifyCodePurpose) {
    localStorage.removeItem(`_PATSNAP_ACCOUNT_MOBILE_VERIFY_COUNT_DOWN_TIME_${verifyCodePurpose}`);
}
export function getMobileVerifyCodeCountDownTime(verifyCodePurpose) {
    const time = localStorage.getItem(`_PATSNAP_ACCOUNT_MOBILE_VERIFY_COUNT_DOWN_TIME_${verifyCodePurpose}`);
    return time && Number(time);
}
// 保存选择的国家区号
export function setAccountCountryCode(countryCode, type) {
    localStorage.setItem(`_PATSNAP_ACCOUNT_COUNTRY_CODE_${type}`, countryCode);
}
export function getAccountCountryCode(type) {
    return localStorage.getItem(`_PATSNAP_ACCOUNT_COUNTRY_CODE_${type}`) || '';
}
// 保存邮箱验证码倒计时
export function setEmailVerifyCodeCountDownTime(purpose = '') {
    localStorage.setItem(`_PATSNAP_ACCOUNT_EMAIL_VERIFY_COUNT_DOWN_TIME_${purpose}`, new Date().getTime().toString());
}
export function removeEmailVerifyCodeCountDownTime(purpose = '') {
    localStorage.removeItem(`_PATSNAP_ACCOUNT_EMAIL_VERIFY_COUNT_DOWN_TIME_${purpose}`);
}
export function getEmailVerifyCodeCountDownTime(purpose = '') {
    const time = localStorage.getItem(`_PATSNAP_ACCOUNT_EMAIL_VERIFY_COUNT_DOWN_TIME_${purpose}`);
    return time && Number(time);
}
export function setAccountBindEmail(email, purpose = '') {
    localStorage.setItem(`_PATSNAP_ACCOUNT_EMAIL_BIND_${purpose}`, email);
}
export function getAccountBindEmail(purpose = '') {
    return localStorage.getItem(`_PATSNAP_ACCOUNT_EMAIL_BIND_${purpose}`) || '';
}
// 发送验证码倒计时 通用
export function setVerifyCodeCountDownTime(name) {
    localStorage.setItem(`_PATSNAP_VERIFY_CODE_COUNT_DOWN_TIME_${name}`, new Date().getTime().toString());
}
export function removeVerifyCodeCountDownTime(name) {
    localStorage.removeItem(`_PATSNAP_VERIFY_CODE_COUNT_DOWN_TIME_${name}`);
}
export function getVerifyCodeCountDownTime(name) {
    const time = localStorage.getItem(`_PATSNAP_VERIFY_CODE_COUNT_DOWN_TIME_${name}`);
    return time && Number(time);
}
export function setVerifyCodeAccountValue(value, name) {
    localStorage.setItem(`_PATSNAP_VERIFY_CODE_ACCOUNT_VALUE_${name}`, value);
}
export function getVerifyCodeAccountValue(name) {
    return localStorage.getItem(`_PATSNAP_VERIFY_CODE_ACCOUNT_VALUE_${name}`) || '';
}
export function getAccountToken() {
    const tokenStr = localStorage.getItem('patsnap-authorizations');
    try {
        const tokenData = JSON.parse(tokenStr);
        return tokenData.token;
    }
    catch (error) {
    }
    return '';
}
export function setAccountPassWordKeepLoginStatus(status) {
    localStorage.setItem('ACCOUNT_PASSWORD_KEEP_LOGIN_STATUS', status);
}
export function getAccountPassWordKeepLoginStatus() {
    return localStorage.getItem('ACCOUNT_PASSWORD_KEEP_LOGIN_STATUS') || 'false';
}
export function setAccountSMSKeepLoginStatus(status) {
    localStorage.setItem('ACCOUNT_SMS_KEEP_LOGIN_STATUS', status);
}
export function getAccountSMSKeepLoginStatus() {
    return localStorage.getItem('ACCOUNT_SMS_KEEP_LOGIN_STATUS') || 'false';
}
