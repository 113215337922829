<template>
    <div id="login-app">
        <router-view />
    </div>
</template>

<script>
import setTitle from '@/infrastructure/utils/setTitle'

export default {
    name: 'ViewRoot',
    inject: ['commonService'],
    watch: {
        CompanyName: {
            handler() {
                setTitle({
                    company_name: this.commonService.globalData.company_name,
                    white_label: this.commonService.globalData.white_label,
                }, `${this.$t('passport.login_title')} - ${this.$t('passport.products.public_pages')}`)
            },
            deep: true,
        },
    },
    computed: {
        CompanyName() {
            return this.commonService.CompanyName
        },
    },
    created() {
        // 获取浏览器相关class
    },
};
</script>

<style lang="scss">
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}
</style>
