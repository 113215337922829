import { psAxios } from '@patsnap/common-ps-http';
import { currentClientUUID } from '@patsnap/uniform-setting';
let lastInterceptorId = -1;
export function applyAuthInterceptor(token) {
    // 防止多次测试登录
    if (lastInterceptorId >= 0) {
        psAxios.interceptors.request.eject(lastInterceptorId);
    }
    lastInterceptorId = psAxios.interceptors.request.use(config => {
        const bearer = `Bearer ${token}`;
        config.headers.Authorization = bearer;
        return config;
    });
}
export function applyTenantIdInterceptor(tenantId, type = '') {
    if (tenantId) {
        psAxios.defaults.headers['X-Tenant-ID'] = tenantId;
    }
    else {
        psAxios.defaults.headers['X-Tenant-ID'] = type === 'clear' ? '' : 'individual';
    }
}
export function applyCommonHeaderConfigInterceptor() {
    psAxios.defaults.headers['X-Patsnap-From'] = 'w-account';
    psAxios.defaults.headers['X-Device-ID'] = currentClientUUID();
}
