import _ from 'lodash';
import { productNameMappingTrialProductId, E_PRODUCT_IDS, } from 'library-components/apis/register/register.type';
import { getVar } from 'library-components/utils/environment';
import { E_Theme } from 'library-components/utils/them';
import { CommonService } from 'library-components/services/commonService';
import { E_PRODUCTS_NAME } from 'library-components/apis/type';
import MessageBox from 'element-ui/lib/message-box';
const SELF_REGISTER_GROUP_KEY = 'self_register_group';
const defaultParamsMap = {
    empty: {
        channel_code: 'MC002',
        channel_source: '官网',
        campaign_promotion: 'SEO',
        lead_source: 'inbound-官网申请试用（SEO）',
        campaign_id: '',
    },
    baidu: {
        channel_code: 'MC001',
        channel_source: '官网',
        campaign_promotion: 'SEM',
        lead_source: 'inbound-官网申请试用（baidu）',
        campaign_id: '',
    },
    360: {
        channel_code: 'MC001',
        channel_source: '官网',
        campaign_promotion: 'SEM',
        lead_source: 'inbound-官网申请试用（360）',
        campaign_id: '',
    },
};
function toDefaultParams(search_channel) {
    search_channel = search_channel || 'empty';
    return _.get(defaultParamsMap, search_channel, {});
}
export class PublicService extends CommonService {
    constructor(_embed) {
        super();
        this._embed = _embed;
    }
    get IsEmbed() {
        return this._embed;
    }
    get MobileNumberMaxLength() {
        if (this.IsRegionCN) {
            return 11;
        }
        else {
            return 32;
        }
    }
    getDefaultProducts(products) {
        let { from } = this.getCommonParams();
        const productIds = _.map(products, m => m.product_id);
        if (from === E_PRODUCTS_NAME.synapse) {
            from = E_PRODUCTS_NAME.synapse_cn;
        }
        if (from && productNameMappingTrialProductId[from] && _.includes(productIds, productNameMappingTrialProductId[from])) {
            const product_ids = [productNameMappingTrialProductId[from]];
            if (from === E_PRODUCTS_NAME.insights) {
                product_ids.push(E_PRODUCT_IDS.Analytics);
            }
            return product_ids;
        }
        // URL中没有指定时，默认选中数据库
        return [E_PRODUCT_IDS.Analytics];
    }
    get TrialRegisterConnectedDays() {
        if (this.IsRegionCN) {
            return '2';
        }
        else {
            return '15';
        }
    }
    get Theme() {
        const theme = this.getParams().theme;
        if (theme) {
            if (_.toLower(theme) === 'blue') {
                return E_Theme.Blue;
            }
            else {
                return E_Theme.Normal;
            }
        }
        if (this.IsRegionCN) {
            return E_Theme.Normal;
        }
        else {
            return E_Theme.Blue;
        }
    }
    /**
     * 试用注册 可选择产品
     * 默认true
     */
    get CanChoiceProducts() {
        const canChoiceProducts = getVar('canChoiceProducts');
        return canChoiceProducts !== 'false';
    }
    getParams() {
        const search_channel = getVar('search_channel');
        let defaultParams = {};
        if (this.IsEmbed && this.IsRegionCN) {
            defaultParams = toDefaultParams(search_channel);
        }
        return _.merge({}, defaultParams, {
            search_channel,
            error_code: getVar('error_code'),
            nickname: getVar('nickname'),
            firstname: getVar('firstname'),
            lastname: getVar('lastname'),
            mobile_number: getVar('mobile_number'),
            verify_code: getVar('verify_code'),
            country_code: getVar('country_code'),
            email: getVar('email'),
            manual_company: getVar('manual_company'),
            department: getVar('department'),
            job_role: getVar('job_role'),
            job_title: getVar('job_title'),
            product_ids: getVar('product_ids') && getVar('product_ids').split(','),
            self_register_group: getVar(SELF_REGISTER_GROUP_KEY),
            created_from: getVar('created_from'),
            channel_code: getVar('channel_code'),
            channel_source: getVar('channel_source'),
            campaign_promotion: getVar('campaign_promotion'),
            lead_source: getVar('lead_source'),
            campaign_id: getVar('campaign_id'),
            media_type: getVar('media_type'),
            search_keywords: getVar('search_keywords'),
            promotion_plan: getVar('promotion_plan'),
            promotion_cell: getVar('promotion_cell'),
            province: getVar('province'),
            city: getVar('city'),
            theme: getVar('theme'),
            from_page: getVar('from_page'),
            from_button: getVar('from_button'),
            official_site_link: getVar('official_site_link'),
            client: getVar('client'),
            identify_token: getVar('identify_token'),
            client_id: getVar('client_id'),
            token: getVar('token'),
            strongPwd: getVar('strongPwd') === 'true',
            remember_me: getVar('remember_me'),
            emailRequired: getVar('emailRequired') === 'true',
            identifierRequired: getVar('identifierRequired') === 'true',
        });
    }
    /**
     * 获取新的销售线索逻辑
     * 1. 国外保留旧逻辑 不用 campaign_id
     * 2. 国内, 用 campaign_id
     *    不传 channel_code  channel_source campaign_promotion lead_source
     */
    getPromotionParams(params) {
        /** 销售线索兼容处理 */
        if (this.IsRegionCN && _.get(params, 'campaign_id')) {
            return _.omit(params, ['channel_code', 'channel_source', 'campaign_promotion', 'lead_source']);
        }
        else {
            return _.omit(params, ['campaign_id']);
        }
    }
    redirectTo(url) { }
    hookLinkElement(container) { }
    onSubmit() { }
    messageBox(options) {
        return MessageBox(options);
    }
}
export class NormalService extends PublicService {
    redirectTo(url) {
        window.location.href = url;
    }
}
