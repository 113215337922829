export const PASSPORT_URI = process.env.VUE_APP_PASSPORT_URI;
export const PASSPORT_CLIENT_ID = process.env.VUE_APP_PASSPORT_CLIENT_ID || '10';
export const PASSPORT_CLIENT_NAME = process.env.VUE_APP_PASSPORT_CLIENT_NAME || 'f-account';
export const I18N_VERSION = process.env.VUE_APP_I18N_VERSION;
export const IS_Prod = process.env.NODE_ENV === 'production';
export const DEPLOY_ENV = process.env.VUE_APP_DEPLOY_ENV

export const ACCOUNT_SERVICE_URI = process.env.VUE_APP_ACCOUNT_SERVICE_URI;
export const ACCOUNT_URI = process.env.VUE_APP_ACCOUNT_URI;
export const ANALYTICS_URI = process.env.VUE_APP_ANALYTICS_URI;
export const YUNCHUANG_URI = process.env.VUE_APP_YUNCHUANG_URI;
export const MESSAGE_SERVICE_URI = process.env.VUE_APP_MESSAGE_SERVICE_URI;
// #region 极验id
export const GEETEST_LOGIN = process.env.VUE_APP_GEETEST_LOGIN;
export const GEETEST_REGISTER = process.env.VUE_APP_GEETEST_REGISTER;
export const GEETEST_MESSAGEAUTHENTICATION = process.env.VUE_APP_GEETEST_MESSAGEAUTHENTICATION;
export const GOOGLE_CAPTCHAID = process.env.VUE_APP_GOOGLE_CAPTCHAID;
export const GOOGLE_CAPTCHAID_INVISIBLE = process.env.VUE_APP_GOOGLE_CAPTCHAID_INVISIBLE;
export const GOOGLE_LOGIN_CLIENT_ID = process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID;
// #endregion
