import cookie from 'js-cookie';
const parseDomain = require('parse-domain');

export function getDomain() {
    const parseResult = parseDomain(document.domain);
    let topDomain = '';
    if (parseResult) {
        const { domain, tld, } = parseResult;
        topDomain = `.${domain}.${tld}`;
    } else {
        topDomain = document.domain;
    }
    return topDomain;
}
export function setEnterLoginCookie() {
    const domain = getDomain();
    cookie.set('is-special-ip-enter-login', 'true', {
        expires: 1000000,
        path: '/',
        domain: domain,
    });
}

export function removeEnterLoginCookie() {
    const domain = getDomain();
    cookie.remove('is-special-ip-enter-login', {
        path: '/',
        domain: domain,
    });
}
