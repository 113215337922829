import { getVar } from 'library-components/utils/environment';
import { getUseLoginSuccessPageApi } from 'library-components/apis/login/login';
import { transformUrlProxy, SLD } from '@patsnap-utils/biz';
import { ANALYTICS_URI } from 'library-components/config/envConfig';
import _ from 'lodash';
import { jumpTo } from './jump';
let redirect_uri = getVar('redirect_uri');
const noRedirect = getVar('no_redirect', 'boolean');
export async function goToMoudle(opts) {
    let redirectUri = '';
    const isDirectLogin = _.get(opts, 'isDirectLogin', false);
    if (isDirectLogin) {
        redirect_uri = '';
        redirectUri = transformUrlProxy(SLD.ANALYTICS, ANALYTICS_URI); // 特殊IP直接登录进入搜索
    }
    else {
        try {
            let getPageBody = {};
            if (redirect_uri) {
                getPageBody = {
                    last_page: redirect_uri,
                };
            }
            const loginSuccessData = await getUseLoginSuccessPageApi(getPageBody);
            const { page, product } = loginSuccessData;
            /**
             *  直接登录的不返回product，其它情况都会返回product
             */
            redirectUri = product ? transformUrlProxy(product, page) : page;
        }
        catch (error) {
            console.log(error);
        }
    }
    if (redirectUri) {
        const protocol = window.location.protocol;
        redirectUri = redirectUri.replace(/^https?:/, protocol);
        if (noRedirect) {
            console.warn('redirect_url:', redirectUri);
            return;
        }
        jumpTo(redirectUri);
    }
}
