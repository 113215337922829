export var E_PROJECT_NAME;
(function (E_PROJECT_NAME) {
    E_PROJECT_NAME["Public"] = "Public";
    E_PROJECT_NAME["Login"] = "Login";
    E_PROJECT_NAME["UserSettings"] = "UserSettings";
})(E_PROJECT_NAME || (E_PROJECT_NAME = {}));
export var E_Language;
(function (E_Language) {
    E_Language["en"] = "en";
    E_Language["cn"] = "cn";
    E_Language["tw"] = "tw";
    E_Language["jp"] = "jp";
})(E_Language || (E_Language = {}));
