import qs from 'qs';
import _ from 'lodash';
export function safeGetLocaleStorage(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
export function getVarsFromUrl(url, keys, type = 'string') {
    const data = {};
    // 截取url中的query
    const _urlArr = url.split('?');
    const _urlQuery = _urlArr[1];
    if (!_urlQuery) {
        return {};
    }
    // 去除url中的hash
    const urlQueryArr = _urlQuery.split('#');
    const urlQuery = urlQueryArr[0];
    if (!urlQuery) {
        return {};
    }
    const queryData = qs.parse(urlQuery, { ignoreQueryPrefix: true });
    _.forEach(keys, key => {
        if (_.has(queryData, key)) {
            const value = _.get(queryData, key) || safeGetLocaleStorage(key);
            if (_.isNull(value) || _.isUndefined(value)) {
                return true;
            }
            if (type === 'boolean') {
                if (_.lowerCase(value) === 'false') {
                    return false;
                }
                else {
                    return true;
                }
            }
            data[key] = value;
        }
    });
    return data;
}
export function getVar(key, type = 'string') {
    // todo!!!
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (_.has(query, key)) {
        const value = _.get(query, key);
        if (_.isNull(value) || _.isUndefined(value)) {
            return true;
        }
        if (type === 'boolean') {
            if (_.lowerCase(value) === 'false') {
                return false;
            }
            else {
                return true;
            }
        }
        return value;
    }
    const res = safeGetLocaleStorage(key);
    if (_.isNull(res)) {
        return undefined;
    }
    return res;
}
export function getVars(keys, type = 'string') {
    const data = getVarsFromUrl(window.location.search, keys, type);
    return data;
}
export function getAllVarsForLogin(keys, type = 'string') {
    let data = getVarsFromUrl(window.location.search, keys, type) || {};
    // @ts-ignore
    const redirect_uri = data.redirect_uri;
    if (redirect_uri) {
        const url = decodeURIComponent(redirect_uri);
        const redirectData = getVarsFromUrl(url, keys, type);
        data = {
            ...redirectData,
            ...data,
        };
    }
    return data;
}
export function postVar(queryData) {
    if (_.isEmpty(queryData)) {
        return '';
    }
    const queryStr = qs.stringify(queryData);
    return queryStr;
}
