export var E_WhiteLabel;
(function (E_WhiteLabel) {
    E_WhiteLabel["OFF"] = "OFF";
    E_WhiteLabel["HIGH"] = "HIGH";
    E_WhiteLabel["LOW"] = "LOW";
    // dummy
    E_WhiteLabel["NONE"] = "NONE";
})(E_WhiteLabel || (E_WhiteLabel = {}));
export var E_MobileVerifyCodePurpose;
(function (E_MobileVerifyCodePurpose) {
    E_MobileVerifyCodePurpose["LOGIN"] = "LOGIN";
    E_MobileVerifyCodePurpose["LOGIN_REGISTER"] = "LOGIN_REGISTER";
    E_MobileVerifyCodePurpose["MOBILE_NUMBER_BINDING"] = "MOBILE_NUMBER_BINDING";
    E_MobileVerifyCodePurpose["UNIONID_BINDING"] = "UNIONID_BINDING";
    E_MobileVerifyCodePurpose["PASSWORD_FORGOT"] = "PASSWORD_FORGOT";
    E_MobileVerifyCodePurpose["REGISTER"] = "REGISTER";
    E_MobileVerifyCodePurpose["SSO_USER_PROFILE"] = "SSO_USER_PROFILE";
    E_MobileVerifyCodePurpose["LOGIN_REGISTER_OR_LOGIN"] = "LOGIN_REGISTER_OR_LOGIN";
})(E_MobileVerifyCodePurpose || (E_MobileVerifyCodePurpose = {}));
export var E_Language;
(function (E_Language) {
    E_Language["en"] = "en";
    E_Language["cn"] = "cn";
    E_Language["tw"] = "tw";
    E_Language["jp"] = "jp";
})(E_Language || (E_Language = {}));
export var E_LanguageLong;
(function (E_LanguageLong) {
    E_LanguageLong["en"] = "en_US";
    E_LanguageLong["cn"] = "zh_CN";
    E_LanguageLong["tw"] = "zh_TW";
    E_LanguageLong["jp"] = "ja_JP";
})(E_LanguageLong || (E_LanguageLong = {}));
